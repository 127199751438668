import {
  AppBar,
  Box,
  MenuItem,
  Select,
  Theme,
  Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  selectBranches,
  selectCurrentBranchId,
  switchBranch
} from 'store/slices/session';
import { useTypedDispatch, useTypedSelector } from 'hooks/useTypedSelector';
import React from 'react';
import UserButton from './UserButton';
import clsx from 'clsx';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  border: none;
  width: 230px;
  @media (max-width: 768px) {
    width: 180px;
  }
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #484850;
    border-radius: 5px 5px 0 0;
  }
  input {
    border: none;
  }
  &:before {
    border: none !important;
  }
  &:after {
    border: none !important;
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  asset: {
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  assetContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(-3),
      padding: theme.spacing(0, 3),
      width: 256
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: '1.20em',
    height: 35,
    width: 35
  },
  flexGrow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  nameContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: 3
  },
  profileMenu: {
    width: 200
  },
  root: {
    boxShadow: 'none'
  }
}));

type Props = {
  className?: string;
  onOpenNavBarMobile?: () => void;
};

function TopBar(props: Props) {
  const { className, ...rest } = props;
  const dispatch = useTypedDispatch();
  const allBranches = useTypedSelector(selectBranches);
  const currentBranchId = useTypedSelector(selectCurrentBranchId);

  const classes = useStyles(props);

  const handleBranchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(switchBranch(event.target.value as string));
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="default">
      <Toolbar>
        <UserButton buttonShadow />
        <div className={classes.assetContainer} />
        <div className={classes.flexGrow} />
        <Box>
          <Box
            color="#9A9B9B"
            fontSize="9px"
            left="8px"
            position="relative"
            top="6px"
          >
            Branch
          </Box>
          <StyledSelect onChange={handleBranchChange} value={currentBranchId}>
            {allBranches.map((branch: any) => (
              <MenuItem key={branch.id} value={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
