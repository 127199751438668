import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// eslint-disable-next-line import/extensions
// import '../deleted/__mocks__';
import 'utils/i18n';
import * as serviceWorker from './serviceWorker';
import { LinearProgress } from '@material-ui/core';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <React.Suspense fallback={<LinearProgress />}>
    <App />
  </React.Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
