import './assets/scss/main.scss';
import './mixins/momentMixin';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router } from 'react-router-dom';
import { SmoothSnackbar } from 'components/SmoothSnackbar';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
  StylesProvider,
  ThemeProvider,
  jssPreset
} from '@material-ui/core';
import { create } from 'jss';
import { createBrowserHistory } from 'history';
import { theme } from 'DS/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import React from 'react';
import ScrollReset from './components/ScrollReset';
import jssExtend from 'jss-plugin-extend';
import routes, { renderRoutes } from './routes';
import store from './store';
import useAxiosInit from 'hooks/app/useAxiosInit';
import useTranslationInit from 'hooks/app/useTranslationInit';


const history = createBrowserHistory();
const jss = create({ insertionPoint: 'jss-insertion-point', plugins: [...jssPreset().plugins, jssExtend()] });

function AppContent() {
  useAxiosInit();
  useTranslationInit();

  return (
    <StylesProvider jss={jss}>
      <StyledThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <SmoothSnackbar />
              <ScrollReset />
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
}

function App() {
  return (
    <StoreProvider store={store}>
      <AppContent />
    </StoreProvider>
  );
}

export default App;
