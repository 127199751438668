import palette from '../../colors/palette';

export default {
  root: {
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    },
    '&$selected': {
      backgroundColor: palette.background.default
    }
  }
};
