/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { LinearProgress } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteConfig } from 'react-router-config';
import AuthGuard from 'components/RouteGuards/AuthGuard';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import React, { Fragment, Suspense, lazy } from 'react';

type RouteConfigExtended = RouteConfig & {
  guard?: React.FunctionComponent<any>,
  layout?: React.FunctionComponent<any>,
  routes?: RouteConfigExtended[],
};

export const renderRoutes = (routes: RouteConfigExtended[] = []) => (
  <Suspense fallback={<LinearProgress />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            exact={route.exact}
            key={i}
            path={route.path}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

// @ts-ignore
// @ts-ignore
const routes: RouteConfigExtended[] = [
  {
    component: () => <Redirect to="/home" />,
    exact: true,
    path: '/'
  },
  {
    // layout: AuthLayout,
    path: '/auth',
    routes: [
      {
        component: lazy(() => import('./views/Auth/Login')),
        exact: true,
        path: '/auth/login'
      },
      {
        component: lazy(() => import('./views/Auth/LoginTemp')),
        exact: true,
        path: '/auth/login2'
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    layout: ErrorLayout,
    path: '/errors',
    routes: [
      {
        component: lazy(() => import('./views/Errors/Error401')),
        exact: true,
        path: '/errors/error-401'
      },
      {
        component: lazy(() => import('./views/Errors/Error404')),
        exact: true,
        path: '/errors/error-404'
      },
      {
        component: lazy(() => import('./views/Errors/Error500')),
        exact: true,
        path: '/errors/error-500'
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    guard: AuthGuard,
    layout: DashboardLayout,
    route: '*',
    routes: [
      {
        component: lazy(() => import('views/Home')),
        exact: true,
        path: '/home'
      },
      {
        component: lazy(() => import('views/Products/RTTS')),
        exact: true,
        path: '/rtts'
      },
      {
        component: lazy(() => import('views/Products/Dashboard')),
        exact: true,
        path: '/dashboard'
      },
      {
        component: lazy(() => import('views/Products/SystemMonitoring')),
        exact: true,
        path: '/system_monitoring'
      },
      {
        component: lazy(() => import('views/Products/BITool')),
        exact: true,
        path: '/bi_tool'
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
