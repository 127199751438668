import { combineReducers } from '@reduxjs/toolkit';
import applicationReducer from './slices/application';
import sessionReducer from './slices/session';

const rootReducer = combineReducers({
  application: applicationReducer,
  session: sessionReducer,
});

export default rootReducer;
