import { colors } from '@material-ui/core';

export default {
  colorPrimary: {
    backgroundColor: colors.blueGrey[50]
  },
  root: {
    borderRadius: 3,
    overflow: 'hidden'
  }
};
