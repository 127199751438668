import { AppThunk, RootState } from 'RootTypes';
import {
  PayloadAction, createSlice
} from '@reduxjs/toolkit';

type SnackbarTypes = 'error' | 'warning' | 'info' | 'success';

interface SnackbarState {
  message?: string;
  type: SnackbarTypes,
  active: boolean,
  autoHideDuration: number,
  autoHide: boolean,
}

interface StateShape {
  snackbar: SnackbarState
}

const initialState: StateShape = {
  snackbar: {
    active: false,
    autoHide: true,
    autoHideDuration: 5000,
    message: '',
    type: 'error'
  }
};

const snackbarSlice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    hideSnackbarMessage: (draft) => {
      draft.snackbar.active = false;
    },
    showSnackbarMessage: (draft, action: PayloadAction<Partial<SnackbarState>>) => {
      draft.snackbar = {
        ...draft.snackbar,
        ...action.payload,
        active: true,
        autoHideDuration: action.payload.autoHide
          ? initialState.snackbar.autoHideDuration
          : null
      };
    }
  }
});

const showSnackbarMessage = (
  message: string,
  type: SnackbarTypes,
  autoHide: boolean = true,
): AppThunk<void> => (dispatch) => {
  dispatch(snackbarSlice.actions.showSnackbarMessage({
    autoHide,
    message,
    type
  }));
};

export const showSnackbarError = (
  message: string,
  autoHide: boolean = true,
): AppThunk<void> => (dispatch) => {
  dispatch(showSnackbarMessage(message, 'error', autoHide));
};

export const showSnackbarInfo = (
  message: string,
  autoHide: boolean = true,
): AppThunk<void> => (dispatch) => {
  dispatch(showSnackbarMessage(message, 'info', autoHide));
};

export const showSnackbarWarning = (
  message: string,
  autoHide: boolean = true,
): AppThunk<void> => (dispatch) => {
  dispatch(showSnackbarMessage(message, 'warning', autoHide));
};

export const hideSnackbarMessage = (): AppThunk<void> => (dispatch) => {
  dispatch(snackbarSlice.actions.hideSnackbarMessage());
};

export const selectSnackbarState = (state: RootState) => state.application.snackbar;

export default snackbarSlice.reducer;
