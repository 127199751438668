import palette from '../../colors/palette';

export default {
  input: {
    '&::placeholder': {
      color: palette.text.secondary,
      opacity: 1
    },
    padding: 8
  },
  root: {
    fontSize: 11
  }
};
