import { selectSessionStatus } from 'store/slices/session';
import { useAuthInit } from 'hooks/app/useAuthInit';
import { useHistory } from 'react-router';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React, { useEffect } from 'react';
import Redirecting from '../../views/Auth/Redirecting';

type Props = {
  children: React.ReactNode
};

function AuthGuard(props: Props) {
  const { children } = props;
  const sessionStatus = useTypedSelector(selectSessionStatus);
  const history = useHistory();
  useEffect(() => {
    if (sessionStatus === 'loggedOut') {
      history.push('/auth/login');
    }
  }, [history, sessionStatus]);
  useAuthInit();

  return (
    <>
      {sessionStatus === 'loggedOut'
        ? <Redirecting />
        : (<>{children}</>)}
    </>
  );
}

export default AuthGuard;
