// eslint-disable-next-line import/extensions
import { Overrides } from '@material-ui/core/styles/overrides';
import MuiButton from './MuiButton';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDivider from './MuiDivider';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItem from './MuiListItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';

const overrides: Overrides = {
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiChip,
  MuiCssBaseline,
  MuiDivider,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiSvgIcon,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  // MuiToggleButton,
  MuiTypography
};

export default overrides;
