// @ts-nocheck
import palette from '../../colors/palette';

export default {
  '@global': {
    // this is the right place to insert global styles.
    '.IconHoverContainer:hover .MuiSvgIcon-root': {
      '&.colorBlue': {
        backgroundColor: palette.info.main,
        color: palette.info[100],
      },
      '&.colorBlueReversed': {
        backgroundColor: palette.info[100],
        color: palette.info.main,
      },
      '&.colorDarkGrey': {
        backgroundColor: palette.darkGrey[800],
        color: palette.darkGrey[100],
      },
      '&.colorDarkRed': {
        backgroundColor: palette.error[100],
        color: palette.error.main,
      },
      '&.colorGreen': {
        backgroundColor: palette.success.main,
        color: palette.success[100],
      },
      '&.colorLightGrey': {
        backgroundColor: palette.lightGrey[800],
        color: palette.darkGrey.main,
      },
      '&.colorRed': {
        backgroundColor: palette.error.main,
        color: palette.error[100],
      }
    },
    '.Mui-disabled .MuiSvgIcon-root': {
      '&.colorDarkGrey': {
        backgroundColor: palette.lightGrey[800],
        color: palette.darkGrey[300],
      },
    },
    '.MuiSvgIcon-root': {
      '&.colorBlue': {
        backgroundColor: palette.info[100],
        color: palette.info.main,
      },
      '&.colorBlueReversed': {
        backgroundColor: palette.info.main,
        color: palette.info[100],
      },
      '&.colorDarkGrey': {
        backgroundColor: palette.lightGrey[800],
        color: palette.darkGrey[800],
      },
      '&.colorDarkRed': {
        backgroundColor: palette.error.main,
        color: palette.error[100],
      },
      '&.colorGreen': {
        backgroundColor: palette.success[200],
        color: palette.success.main
      },
      '&.colorLightGrey': {
        backgroundColor: palette.darkGrey[800],
        color: palette.lightGrey.main,
      },
      '&.colorRed': {
        backgroundColor: palette.error[100],
        color: palette.error.main,
      },
      borderRadius: 200
    },
  },
};
