import { createTheme } from '@material-ui/core';
import overrides from './overrides';
import palette from '../colors/palette';
import shadows from './shadows';
import typography from './typography';

const baseTheme = {
  overrides,
  palette,
  shadows,
  typography
};

export const theme = createTheme(baseTheme);
