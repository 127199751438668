import { ColorPartial, PaletteOptions } from '@material-ui/core/styles/createPalette';
import { colors } from '@material-ui/core';
import fadeColor from './fadeColor';

const opacityCalculator = (color: string): ColorPartial => ({
  100: fadeColor(color, 0.1),
  200: fadeColor(color, 0.2),
  300: fadeColor(color, 0.3),
  400: fadeColor(color, 0.4),
  500: fadeColor(color, 0.5),
  600: fadeColor(color, 0.6),
  700: fadeColor(color, 0.7),
  800: fadeColor(color, 0.8),
  900: fadeColor(color, 0.9),
});

const white = '#FFFFFF';
const info = '#69C9FF';
const success = '#40DBA3';
const error = '#E4756D';
const warning = '#E5A071';
const darkGrey = '#494949';
const lightGrey = '#F3F5F6';
const yellow = '#F3BF32';

const options: PaletteOptions = {
  background: {
    default: '#F3F5F6',
    paper: white
  },
  darkGrey: {
    main: darkGrey,
    ...opacityCalculator(darkGrey),
    contrastText: white
  },
  divider: colors.grey[200],
  error: {
    main: error,
    ...opacityCalculator(error),
    contrastText: white
  },
  icon: colors.blueGrey[600],
  info: {
    main: info,
    ...opacityCalculator(info),
    contrastText: white
  },
  lightGrey: {
    main: lightGrey,
    ...opacityCalculator(lightGrey),
    contrastText: white
  },
  link: colors.blue[800],
  primary: {
    contrastText: white,
    dark: '#3f9ecc',
    light: '#aeffff',
    main: '#78cfff'
  },
  secondary: {
    contrastText: white,
    dark: '#b35451',
    light: '#ffb4ac',
    main: '#e8837d'
  },
  success: {
    main: success,
    ...opacityCalculator(success),
    contrastText: white
  },
  text: {
    primary: darkGrey,
    secondary: colors.blueGrey[600]
  },
  warning: {
    main: warning,
    ...opacityCalculator(warning),
    contrastText: white
  },
  yellow: {
    main: yellow,
    ...opacityCalculator(yellow),
    contrastText: white
  },
};

export default options;
