import { Alert } from '@material-ui/lab';
import {
  Snackbar, Theme, makeStyles,
} from '@material-ui/core';
import { hideSnackbarMessage, selectSnackbarState } from 'store/slices/application';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/useTypedSelector';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    fontSize: '14px'
  }
}));

export function SmoothSnackbar() {
  const classes = useStyles();
  const snackbarState = useTypedSelector(selectSnackbarState);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(hideSnackbarMessage());
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={snackbarState.autoHideDuration}
      onClose={handleClose}
      open={snackbarState.active}
    >
      <Alert
        className={classes.alert}
        onClose={handleClose}
        severity={snackbarState.type}
        variant="filled"
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
