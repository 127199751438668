export default {
  adornedStart: {
    paddingLeft: 8
  },
  input: {
    padding: 8
  },
  inputAdornedStart: {
    paddingLeft: 6
  },
  notchedOutline: {
    // borderColor: 'rgba(0,0,0,0.15)'
  },
  root: {
    // backgroundColor: '#F3F5F6'
  }
};
