import React from 'react';
import TopBar from './TopBar';
import styled from 'styled-components';

const StyledDiv = styled.div`
  flex-grow: 1;
  max-width: 100%;
  overflow-x: hidden;
`;

const StyledContainer = styled.div<{ homePageView: boolean }>`
  @media all and (-ms-high-contrast:none) {
    height: 0;
  }
  display: flex;
  min-height: 100vh;
  padding: ${({ homePageView }) => (homePageView ? '35px' : '8px')} 8px 8px;
  max-width: ${({ homePageView }) => (homePageView ? '1300px' : 'none')};
  margin: ${({ homePageView }) => (homePageView ? 'auto' : 'initial')};
`;

type Props = {
  children: React.ReactNode;
};

function Dashboard(props: Props) {
  const { children } = props;

  return (
    <>
      {window.location.href.includes('home') && <TopBar />}
      <StyledContainer homePageView={window.location.href.includes('home')}>
        <StyledDiv>{children}</StyledDiv>
      </StyledContainer>
    </>
  );
}

export default Dashboard;
