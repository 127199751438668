import {
  LinearProgress, Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Redirecting() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        <LinearProgress />
      </div>
    </>
  );
}

export default Redirecting;
