import Color from 'color';

const fadeColor = (color: string, alpha: number): string => {
  const colorObj = Color(color);
  const opacity = 255 * (1 - alpha);
  const red = colorObj.red() * alpha + opacity;
  const green = colorObj.green() * alpha + opacity;
  const blue = colorObj.blue() * alpha + opacity;

  return Color.rgb(red, green, blue).hex();
};

export default fadeColor;
