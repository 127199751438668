export default {
  fontSizeLarge: {
    fontSize: 32
  },
  fontSizeSmall: {
    fontSize: 16
  },
  root: {
    '&.MuiSvgIcon-contained': {
      borderRadius: 100
    },
    '&.MuiSvgIcon-fontSizeMedium': {
      fontSize: 24
    }
  }
};
