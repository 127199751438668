import { showSnackbarError } from 'store/slices/application';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import axios from 'utils/axiosUtils';

const useAxiosInit = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios.interceptors.response.use((response) => response, (error) => {
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        if (error.response.status !== 401) {
          const message = `${error.response.status}: `;
          const detail = error.response.data.detail ?? error.response.data.title;
          dispatch(showSnackbarError(message + detail));
        }
      } else {
        // Something happened in setting up the request and triggered an Error
        dispatch(showSnackbarError(error.message));
        // console.log('Error', error.message);
      }
      return Promise.reject(error);
    });
  }, [dispatch]);
};

export default useAxiosInit;
