import {
  Avatar,
  Box,
  Button,
  ClickAwayListener
} from '@material-ui/core';
import { logout, selectSessionUser } from '../../store/slices/session';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import React, { useRef, useState } from 'react';

type Props = {
  buttonShadow?: boolean
};


function UserButton(props: Props) {
  const { buttonShadow } = props;
  const dispatch = useDispatch();
  const user = useTypedSelector(selectSessionUser);

  const profileMenuRef = useRef(null);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const handleProfileMenuOpen = () => {
    setOpenProfileMenu(true);
  };

  const handleProfileMenuClose = () => {
    setOpenProfileMenu(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box
      boxShadow={buttonShadow && openProfileMenu ? 1 : 0}
      style={openProfileMenu ? {
        backgroundColor: 'white',
        borderRadius: 16,
      } : {}}
    >
      {!openProfileMenu && (
      <Button color="inherit" onClick={handleProfileMenuOpen}>
        <Avatar
          ref={profileMenuRef}
          style={{
            fontSize: 11,
            height: 24,
            width: 24
          }}
        >
          {`${user?.firstName?.[0]}${user?.lastName?.[0]}`}
        </Avatar>
        <Box fontSize="11px" height="30px" lineHeight="30px" ml="4px">
          {`${user?.firstName} ${user?.lastName}`}
        </Box>
      </Button>
      )}
      {openProfileMenu && (
      <ClickAwayListener onClickAway={handleProfileMenuClose}>
        <Button color="inherit" onClick={handleLogout}>
          <Avatar
            ref={profileMenuRef}
            style={{
              fontSize: 11,
              height: 24,
              width: 24
            }}
          >
            {`${user?.firstName?.[0]}${user?.lastName?.[0]}`}
          </Avatar>
          <Box fontSize="11px" height="30px" ml="4px" textAlign="start">
            {`${user?.firstName} ${user?.lastName}`}
            <br />
            <Box color="#E4756D" fontWeight="700">
              LOGOUT
            </Box>

          </Box>
        </Button>
      </ClickAwayListener>
      )}
    </Box>
  );
}

export default UserButton;
