import axios from 'axios';

const instance = axios.create();

const environment = (window as any).dynamicEnv.NODE_DYNAMIC_ENVIRONMENT;
if (environment === 'dev') {
  instance.defaults.baseURL = 'http://quattrogatewayassetclient.dev.bordatechdev.com';
} else if (environment === 'test') {
  instance.defaults.baseURL = 'http://quattrogatewayassetclient.dev.bordatechdev.com';
} else {
  instance.defaults.baseURL = 'http://quattrogatewayassetclient.dev.bordatechdev.com';
}

export default instance;
