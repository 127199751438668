import { AppThunk, RootState } from 'RootTypes';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { showSnackbarError } from './application';
import axios from 'axios';

interface User {
  branches: Branch[],
  deploymentId: string,
  firmId: string,
  firmLighthouseWCFURL: string,
  firstName: string,
  id: string,
  language: number,
  lastName: string,
  username: string,
  token: string
}

interface Branch {
  code: string,
  id: string
  name: string,
}

interface Session {
  status: 'inProgress' | 'loggedIn' | 'loggedOut',
  user?: User,
  selectedBranchId?: string,
  turboardToken?: string
}

export interface LoginRequestModel {
  username: string,
  password: string,
  rememberMe: boolean
}

interface FirmModel {
  BranchContractList: BranchModel[],
  DeploymentGUID: string,
  FirmGUID: string,
  FirmName: string,
  ID: number
  LighthouseWCFURL: string,
  NotificationServiceURL: string,
}

interface BranchModel {
  BranchCode: string,
  BranchGUID: string
  BranchName: string,
}

interface LoginResponseModel {
  AuthorizedFirmContractList: FirmModel[],
  ExceptionMessage: string,
  ID: number
  Language: number,
  Token: string,
  UserFirstName: string,
  UserGUID: string,
  UserLastName: string,
  UserName: string
}

// L3 Demokit
const applicationGUID = '709645aa-efd1-42e1-9a55-649c8205d932';
const applicationDeploymentGUID = 'aab04374-b164-4b0a-856f-8f153bd278cd';

const computerCode = 'xxxx';
const storageKey = 'session';
const localStorageBranchKey = 'selectedBranchId';

const createUser = (loginResponse: LoginResponseModel) => {
  const firm = loginResponse.AuthorizedFirmContractList
    .find((i) => i.DeploymentGUID === applicationDeploymentGUID)
    ?? loginResponse.AuthorizedFirmContractList[0];

  const user: User = {
    branches: firm.BranchContractList
      .map((b) => ({ code: b.BranchCode, id: b.BranchGUID, name: b.BranchName })),
    deploymentId: firm.DeploymentGUID,
    firmId: firm.FirmGUID,
    firmLighthouseWCFURL: firm.LighthouseWCFURL,
    firstName: loginResponse.UserFirstName,
    id: loginResponse.UserGUID,
    language: loginResponse.Language,
    lastName: loginResponse.UserLastName,
    token: loginResponse.Token,
    username: loginResponse.UserName
  };

  return user;
};

const createInitialState = (): Session => {
  const fromSessionStorage = sessionStorage.getItem(storageKey);
  const fromLocalStorage = localStorage.getItem(storageKey);

  const serialized = fromSessionStorage ?? fromLocalStorage;

  if (!serialized) {
    return {
      selectedBranchId: null,
      status: 'loggedOut',
      user: null
    };
  }

  const loginResponse = JSON.parse(serialized) as LoginResponseModel;

  const user = createUser(loginResponse);

  return {
    selectedBranchId: getSelectedBranchId(user.branches),
    status: 'loggedIn',
    user
  };
};

const sessionSlice = createSlice({
  initialState: createInitialState(),
  name: 'session',
  reducers: {
    login: (draft, action: PayloadAction<LoginResponseModel>) => {
      draft.status = 'loggedIn';
      draft.user = createUser(action.payload);
      draft.selectedBranchId = getSelectedBranchId(draft.user.branches);
    },
    logout: (draft) => {
      draft.status = 'loggedOut';
      draft.user = null;
      draft.selectedBranchId = null;
    },
    setTurboardToken: (draft, action: PayloadAction<string>) => {
      draft.turboardToken = action.payload;
    },
    switchBranch: (draft, action: PayloadAction<string>) => {
      draft.selectedBranchId = action.payload;
      localStorage.setItem(localStorageBranchKey, draft.selectedBranchId);
    }
  }
});

function getSelectedBranchId(branches: Branch[]) {
  const branchId = localStorage.getItem(localStorageBranchKey);

  return branches.find((b) => b.id === branchId) ? branchId : branches[0].id;
}

export const login = (loginRequestModel: LoginRequestModel): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post<LoginResponseModel>('/authapi/login/LoginMobileClient', {}, {
      headers: {
        ApplicationGUID: applicationGUID,
        ComputerCode: computerCode,
        password: loginRequestModel.password,
        username: loginRequestModel.username
      }
    });

    if (response.status !== 200) {
      throw new Error('Response is not success');
    }

    if (response.data.ExceptionMessage) {
      throw new Error(response.data.ExceptionMessage);
    }

    if (response.data.AuthorizedFirmContractList.length === 0) {
      throw new Error('Not authorized for any firm');
    }

    if (response.data.AuthorizedFirmContractList[0].BranchContractList.length === 0) {
      throw new Error('Not authorized for any branch');
    }

    response.data.UserName = loginRequestModel.username;
    const serialized = JSON.stringify(response.data);
    if (loginRequestModel.rememberMe) {
      localStorage.setItem(storageKey, serialized);
    } else {
      sessionStorage.setItem(storageKey, serialized);
    }

    dispatch(sessionSlice.actions.login(response.data));
  } catch (error: any) {
    dispatch(showSnackbarError(error.message));
    throw error;
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(sessionSlice.actions.logout());
  sessionStorage.removeItem(storageKey);
  localStorage.removeItem(storageKey);
};

export const requestTurboardToken = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post<{ token: string }>('https://turboard.bordatech.com/api/v1/usertoken/', {
      auth_key: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE4OTQxOTc1MjYsImlzX3ZhbGlkIjp0cnVlfQ.XuOWHCMKruCTTWzFX1rLtU7pgl3eftt4XhFKSEXoggc',
      username: 'demo_default_user'
    });
    dispatch(sessionSlice.actions.setTurboardToken(response.data.token));
  } catch (e) {
    dispatch(showSnackbarError('Turboard authentication failed'));
  }
};

export const { switchBranch } = sessionSlice.actions;

export const selectSessionStatus = (state: RootState) => state.session.status;
export const selectSessionUser = (state: RootState) => state.session.user;
export const selectBranches = (state: RootState) => state.session.user.branches;
export const selectCurrentBranchId = (state: RootState) => state.session.selectedBranchId;

export const selectRTTSUrl = createSelector(
  selectSessionUser,
  selectCurrentBranchId,
  (user, currentBranchId) => {
    const language = user.language === 1 ? 'en' : 'tr';

    let url = 'https://demol3.bordatech.com/demokitrtts/#/';
    url += `${user.token}/`;
    url += `${user.username}/`;
    url += `${computerCode}/`;
    url += `${applicationDeploymentGUID}/`;
    url += `${currentBranchId}//${language}/0/1/`;
    url += `${user.firmLighthouseWCFURL.replace('https://', '').replace('http://', '')}/`;
    url += 'tracking/filters';
    return url;
  }
);

export const selectDashboardUrl = createSelector(
  selectCurrentBranchId,
  (branchId) => `https://demol3.bordatech.com/demokit/l3dashboard/#/patient/patient-list?branchGuid=${branchId}`
);

export const selectSystemMonitoringUrl = (_state: RootState) => 'https://mon.bordatech.com/centreon/main.php?p=1&autologin=1&useralias=borda_auto&token=GSWkoG82';

export const selectBIToolAuthenticatorUrl = (state: RootState) => `https://turboard.bordatech.com/#/?token=${state.session.turboardToken}&persistToStorage=true`;
export const selectBIToolUrl = (_state: RootState) => 'https://turboard.bordatech.com/#/';

export default sessionSlice.reducer;
